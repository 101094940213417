/* istanbul ignore file */
import { configureLocalization } from '@lit/localize'

const targetLocals = ['de']

let { getLocale, setLocale } = configureLocalization({
  sourceLocale: 'en',
  targetLocales: ['de'],
  loadLocale: (locale) => import(`#js/locale/${locale}`)
})

const orgSetLocale = setLocale

setLocale = (locale) => {
  if (targetLocals.includes(locale)) {
    orgSetLocale(locale)
  }
}

export { getLocale, setLocale }
